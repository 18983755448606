import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ButtonMain, PageLoader, SearchInput } from "components";
import { FavorSmallCard, SortBy, sortItems } from "fragments";
import { favorsActions } from "store";
import { FindLoad, SaveParams, useModal } from "utils";
import { Svg } from "assets";

export const ItemsModal = ({ type }) => {
  const [showActiveItem, setShowActiveItem] = useState([]);

  const { favorsForFilter } = useSelector((state) => ({
    favorsForFilter: state.favors?.favorsForFilter?.favors,
  }));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const info = location?.state;
  const { close } = useModal();
  const loading = FindLoad("GET_FAVORS_FOR_FILTER");
  const activeItems = info?.activeItems;
  const amount = info?.amount;

  useEffect(() => {
    if (activeItems?.length) {
      setShowActiveItem(activeItems);
    }
  }, [activeItems]);

  useEffect(() => {
    const params = {
      ...info,
      type,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;
    delete params?.sortValue;

    dispatch(favorsActions.getFavorsForFilter(params));
  }, [
    dispatch,
    info?.skip,
    info?.limit,
    info?.orderType,
    info?.sortType,
    info?.name,
    type,
  ]);

  const toggleItems = (item) => {
    let amountItems = 0;

    showActiveItem.forEach((element) => {
      amountItems += element?.price;
    });

    if (showActiveItem.some((element) => element?.id === item?.id)) {
      setShowActiveItem((prevState) =>
        prevState.filter((element) => element?.id !== item?.id)
      );
    } else {
      if (amountItems + item?.price <= amount) {
        setShowActiveItem([...showActiveItem, item]);
      } else {
        toast("You can't add more items");
      }
    }
  };

  return (
    <div className="see-items-modal-wrapper">
      <div className="modal2-header">
        <div>
          <div className="modal2-header_title">Choose Items</div>
          {amount && <p className="see-items-modal-budget">Budget: {amount}</p>}
        </div>
        <Svg.Close onClick={close} />
      </div>
      <div className="modal2-body-footer-wrapper">
        <div className="modal2-body-wrapper">
          <div className="see-items-search_input-filter">
            <SearchInput
              placeholder={"Search by name or cost..."}
              searchKey={"name"}
              className="see-items-search_input"
            />

            <SortBy items={sortItems} Icon={Svg.Sort2Svg} />
          </div>
          <div className="see-items-box">
            {loading ? (
              <PageLoader
                height={"100%"}
                wrapperHeight={"100%"}
                wrapperWidth={"100%"}
              />
            ) : (
              !!favorsForFilter?.length &&
              favorsForFilter?.map((item, index) => (
                <FavorSmallCard
                  className="see-items_single"
                  item={item}
                  key={index}
                  activeItems={showActiveItem}
                  onClick={(item) => toggleItems(item)}
                  handleDelete={(item) => toggleItems(item)}
                />
              ))
            )}
          </div>
        </div>

        <div className="modal2-footer-wrapper">
          <button
            type="button"
            className="modal2-footer-cancel-button"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
          <ButtonMain
            type="button"
            text="Send"
            onClick={() => {
              SaveParams(location?.pathname, navigate, {
                ...info,
                activeItems: [...showActiveItem],
              });
              close();
            }}
          />
        </div>
      </div>
    </div>
  );
};
