import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PaginationItem, PageLoader, TextToolTip } from "components";
import { NotYet } from "./notYet";
import { FindLoad, ProfileInfo, SaveParams } from "utils";
import { Svg } from "assets";

export const FullTableAccordion = ({
  head,
  body,
  list,
  mobileCard,
  loadingType,
  handleClick,
  listCount,
}) => {
  const loader = FindLoad(loadingType ? loadingType : "");
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;
  const { ifAdmin } = ProfileInfo();

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleClick(i, name), i);
      } else {
        return item?.rowText(i);
      }
    }
  };

  const handleSort = (current) => {
    const params = {
      ...info,
    };
    params.sortType = current;
    if (info?.orderType === "DESC") {
      params.orderType = "ASC";
    } else {
      params.orderType = "DESC";
    }
    SaveParams(location?.pathname, navigator, params);
  };

  const handleClickRow = (item, i) => {
    if (item?.notClickable !== true && handleClick) {
      handleClick(i);
    }
  };
  if (loader?.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="full-table-accordion">
      <div className="full-table-accordion-wrapper">
        <div className="full-table-accordion-container">
          <div>
            <div className="accordion-table-head">
              <div className="space-between" style={{ paddingRight: "56px" }}>
                {head?.map((i, j) => (
                  <div
                    key={j}
                    className="accordion-table-cell-box"
                    style={{ width: i?.width }}
                  >
                    <p>{i?.title}</p>
                    {i?.sortType && (
                      <button
                        style={
                          info?.orderType && info?.sortType === i?.sortType
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                        onClick={() => handleSort(i.sortType)}
                      >
                        <Svg.SortSvg
                          className="table-cell_sort-svg"
                          style={
                            info?.sortType === i?.sortType &&
                            info?.orderType === "DESC"
                              ? { transform: "rotate(180deg)" }
                              : {}
                          }
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="accordion-table-body-donation">
              {list?.length ? (
                list?.map((i, j) => (
                  <Accordion key={j}>
                    <AccordionSummary
                      expandIcon={<Svg.ArrowDown />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {body?.map((item, ke) => {
                        if (item) {
                          return (
                            <div
                              style={{ width: item?.width }}
                              className="accordion-table-row"
                              key={ke}
                              onClick={() => handleClickRow(item, i)}
                            >
                              {renderItems(i, item) || "Not Set"}
                            </div>
                          );
                        }
                      })}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="donation-accordion-details-wrapper">
                        <div className="donors-contact-wrapper">
                          <div className="donors-contact-body">
                            <div className="donors-contact-item">
                              <p className="charity-creator-name show-name">
                                Donor Email:
                              </p>

                              <TextToolTip text={i?.email} content={i?.email} />
                            </div>
                          </div>
                        </div>
                        {i?.keepAmount && (
                          <div className="creators-part-wrapper">
                            <p className="creators-part-title">
                              Creator’s Portion
                            </p>
                            <div className="charities-part-box">
                              <div className="charities-part-table-header">
                                <p className="charities-part-table-header-item creator-name-header">
                                  Creator Name
                                </p>

                                <p className="charities-part-table-header-item creator-net-amount-header">
                                  Net Amount
                                </p>

                                <p className="charities-part-table-header-item creator-service-fee-header">
                                  Service Fee
                                </p>

                                <p className="charities-part-table-header-item creator-received-header">
                                  Received
                                </p>
                              </div>
                              <div className="creators-part-body-wrapper">
                                <div className="creators-part-body">
                                  <div className="creators-part-body-item creator-name-block">
                                    <p className="charity-creator-name">
                                      Creator Name:
                                    </p>

                                    <TextToolTip
                                      classNameText="charity-creator-desc"
                                      text={i?.fundraiser?.owner?.firstName}
                                      content={i?.fundraiser?.owner?.firstName}
                                    />
                                  </div>

                                  <div className="creators-part-body-item net-amount">
                                    <p className="charity-creator-name">
                                      Net Amount:
                                    </p>

                                    <TextToolTip
                                      classNameText="charity-creator-desc"
                                      arrow
                                      priceRow
                                      allowPlusPref
                                      text={
                                        +i?.keepAmount?.netAmount +
                                        +i?.keepAmount?.serviceFee
                                      }
                                      content={
                                        +i?.keepAmount?.netAmount +
                                        +i?.keepAmount?.serviceFee
                                      }
                                    />
                                  </div>

                                  <div className="creators-part-body-item service-fee">
                                    <p className="charity-creator-name">
                                      Service Fee:
                                    </p>

                                    <TextToolTip
                                      classNameText="charity-creator-desc"
                                      priceRow
                                      allowMinusPref
                                      arrow
                                      text={
                                        ifAdmin ? 0 : +i?.keepAmount?.serviceFee
                                      }
                                      content={
                                        ifAdmin ? 0 : +i?.keepAmount?.serviceFee
                                      }
                                    />
                                  </div>

                                  <div className="creators-part-body-item total-received">
                                    <p className="charity-creator-name">
                                      Total Received:
                                    </p>

                                    <TextToolTip
                                      classNameText="charity-creator-desc"
                                      priceRow
                                      arrow
                                      allowPlusPref
                                      text={+i?.keepAmount?.netAmount}
                                      content={+i?.keepAmount?.netAmount}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {!!i?.charities.length && (
                          <div className="charities-part-wrapper">
                            <p className="creators-part-title">
                              Charities’ Portion
                            </p>

                            <div className="charities-part-box">
                              <div className="charities-part-table-header">
                                <p className="charities-part-table-header-item creator-name-header">
                                  Charity Name
                                </p>
                                <p className="charities-part-table-header-item creator-net-amount-header">
                                  Net Amount
                                </p>
                                <p className="charities-part-table-header-item creator-service-fee-header">
                                  Service Fee
                                </p>
                                <p className="charities-part-table-header-item creator-received-header">
                                  Received
                                </p>
                              </div>

                              <div className="creators-part-body-wrapper">
                                {i?.charities?.map((item, key) => (
                                  <div className="creators-part-body" key={key}>
                                    <div className="creators-part-body-item creator-name-block">
                                      <p className="charity-creator-name">
                                        Charity Name:
                                      </p>

                                      <TextToolTip
                                        classNameText="charity-creator-desc"
                                        text={item?.charityName}
                                        content={item?.charityName}
                                      />
                                    </div>

                                    <div className="creators-part-body-item net-amount">
                                      <p className="charity-creator-name">
                                        Net Amount:
                                      </p>

                                      <TextToolTip
                                        classNameText="charity-creator-desc"
                                        priceRow
                                        arrow
                                        allowPlusPref
                                        text={
                                          +item?.netAmount + +item?.serviceFee
                                        }
                                        content={
                                          +item?.netAmount + +item?.serviceFee
                                        }
                                      />
                                    </div>

                                    <div className="creators-part-body-item service-fee">
                                      <p className="charity-creator-name">
                                        Service Fee:
                                      </p>

                                      <TextToolTip
                                        classNameText="charity-creator-desc"
                                        priceRow
                                        allowMinusPref
                                        arrow
                                        text={+item?.serviceFee}
                                        content={+item?.serviceFee}
                                      />
                                    </div>

                                    <div className="creators-part-body-item total-received">
                                      <p className="charity-creator-name">
                                        Total Received:
                                      </p>

                                      <TextToolTip
                                        classNameText="charity-creator-desc"
                                        priceRow
                                        arrow
                                        allowPlusPref
                                        text={+item?.netAmount}
                                        content={+item?.netAmount}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <NotYet height={"60vh"} />
              )}
            </div>
          </div>
        </div>

        {listCount > 0 && <PaginationItem count={listCount} list={list} />}
      </div>
      <div className="table-wrapper-mobile">
        <div className="table-wrapper-mobile-wrapper">
          {list?.length ? (
            list?.map((i, j) => (
              <div className="table-mobile-card" key={j}>
                {mobileCard?.map((item, ke) => {
                  if (item) {
                    return (
                      <div key={ke} onClick={() => handleClickRow(item, i)}>
                        {renderItems(i, item) || "Not Set"}
                      </div>
                    );
                  }
                })}
              </div>
            ))
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            >
              <NotYet height={"60vh"} />
            </div>
          )}
        </div>
        {listCount > 0 && <PaginationItem count={listCount} list={list} />}
      </div>
    </div>
  );
};
