import { ButtonMain } from "../buttons";

export const TitleAndAction = ({
  title,
  btnText,
  startIcon,
  handleClick,
  customComponent,
  mobileBtnText,
}) => {
  return (
    <div className="title-and-action-box">
      <h1 className="page-global-title">{title}</h1>
      {btnText && (
        <ButtonMain
          onClick={handleClick}
          text={btnText}
          startIcon={startIcon}
          mobileBtnText={mobileBtnText}
        />
      )}
      {customComponent}
    </div>
  );
};
