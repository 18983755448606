import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { charityActions } from "store";
import {
  TitleAndAction,
  SearchInput,
  FullTable,
  ButtonMain,
  SimpleTabs,
  DeleteModal,
} from "components";
import { Svg } from "assets";
import {
  charitiesBody,
  charitiesHead,
  CHARITY_TABLE_ACTION_TYPE,
  charityMobileCard,
} from "./fragments";
import { CreateCharity } from "./createCharity";
import { limit, useModal, useWindowDimensions } from "utils";
import {
  charityStatuses,
  DELETE_PENDING_CHARITY,
  PENDING_CHARITY_TABLE_ACTION_TYPE,
  pendingCharitiesBody,
  pendingCharitiesHead,
  pendingCharityMobileCard,
  tabList,
} from "./fragments/constants";

export const Charities = () => {
  const { charities, pendingCharities, allPendingCharities } = useSelector(
    (state) => ({
      charities: state.charity.charities,
      pendingCharities: state.charity.pendingCharities,
      allPendingCharities: state.charity.allPendingCharities,
    })
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const info = location?.state;
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(charityActions.getAllPendingCharities());
  }, []);

  const renderParams = () => {
    const params = {
      ...info,
      limit,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;
    return params;
  };

  const getCharities = () => {
    dispatch(charityActions.getCharities(renderParams()));
  };

  const getAllPendingCharities = (load) => {
    const loading = load === "noLoad" ? "noLoad" : true;
    dispatch(charityActions.getPendingCharities(renderParams(), loading));
  };

  useEffect(() => {
    const params = {
      ...info,
      limit,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;

    if (!info?.tab || info?.tab === charityStatuses.ALL) {
      getCharities();
    } else {
      getAllPendingCharities();
    }
  }, [dispatch, info]);

  const deleteRow = (item, name) => {
    if (name === "delete") {
      openModal(
        <DeleteModal
          title={"Are you sure you want to delete this charity request?"}
          description={"This action is irreversible, and once deleted."}
          handleDelete={() =>
            dispatch(charityActions.deletePendingCharities(item?.id))
          }
          afterSuccess={() => getAllPendingCharities("noLoad")}
          actionType={DELETE_PENDING_CHARITY}
        />
      );
    }
  };

  return (
    <div className="charities-page">
      <TitleAndAction
        title="Charities"
        customComponent={
          <div className="align-center" style={{ gap: "16px" }}>
            <div className="search-box-tablet">
              <SearchInput
                placeholder={"Search by name..."}
                searchKey={"name"}
              />
            </div>
            <ButtonMain
              type={"button"}
              onClick={() =>
                openModal(<CreateCharity />, {
                  padding: "noPaddings",
                  noShowCloseBrn: true,
                })
              }
              text="Create Charity"
              startIcon={<Svg.AddWhiteCircle />}
              mobileBtnText={"notShow"}
            />
          </div>
        }
      />

      <div className="tab-and-search">
        <SimpleTabs
          list={tabList(
            +allPendingCharities?.count > 0 && allPendingCharities?.count
          )}
          defaultTab={charityStatuses.ALL}
        />
      </div>

      <div className="search-box-mobile" style={{ margin: 0 }}>
        <SearchInput placeholder={"Search by name..."} searchKey={"name"} />
      </div>

      {info?.tab === charityStatuses.REQUESTED ? (
        <FullTable
          classNameWrapper="charities-table-wrapper"
          head={pendingCharitiesHead}
          body={pendingCharitiesBody}
          mobileCard={pendingCharityMobileCard}
          loadingType={PENDING_CHARITY_TABLE_ACTION_TYPE}
          list={pendingCharities?.charityRequests}
          listCount={pendingCharities?.count}
          handleClick={deleteRow}
        />
      ) : (
        <FullTable
          classNameWrapper="charities-table-wrapper"
          head={charitiesHead(width)}
          body={charitiesBody(width)}
          mobileCard={charityMobileCard}
          loadingType={CHARITY_TABLE_ACTION_TYPE}
          list={charities?.charities}
          listCount={charities?.count}
          handleClick={(item) => navigate(`/charities/${item?.id}`)}
        />
      )}
    </div>
  );
};
