import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  BackBtn,
  CheckboxesTags,
  SelectRadio,
  InputMain,
  TextAreaMain,
  MinLoader,
  Upload,
  DateCalendarForm,
} from "components";
import { Svg } from "assets";
import {
  charityActions,
  fundraiserActions,
  httpRequestsOnLoadActions,
  httpRequestsOnSuccessActions,
} from "store";
import {
  fundraiserCategories,
  CREATE_ACTION_TYPE,
  fundraiserStatuses,
} from "./fragments";
import { FindLoad, FindSuccess, SaveParams } from "utils";
import axios from "axios";
import { UPLOAD_FUNDRAISER_IMAGE } from "./fragments/constants";

export const CreateFundraiser = () => {
  const { charities } = useSelector((state) => ({
    charities: state.charity.charities,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, control, setError } = useForm({});
  const [clickedButton, setClickedButton] = useState("");
  const loader = FindLoad(CREATE_ACTION_TYPE);
  const uploadLoader = FindLoad(UPLOAD_FUNDRAISER_IMAGE);
  const success = FindSuccess(CREATE_ACTION_TYPE);
  const navigator = useNavigate();
  const [images, setImages] = useState([]);
  const [main, setMain] = useState(0);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION_TYPE));
      const tab =
        clickedButton === "saveAsDraft"
          ? fundraiserStatuses.DRAFT
          : fundraiserStatuses.LIVE;
      SaveParams("/fundraisers", navigator, { tab: tab });
    }
  }, [success]);

  useEffect(() => {
    dispatch(charityActions.getCharities({ status: "ACTIVE" }));
  }, [dispatch]);

  const navigateBack = () => {
    navigate("/fundraisers");
    setError("email", {});
  };

  const onSubmit = async (data) => {
    const params = {
      ...data,
    };

    if (images.length > 0) {
      dispatch(
        httpRequestsOnLoadActions.appendLoading(UPLOAD_FUNDRAISER_IMAGE)
      );
      const formData = new FormData();
      const endpoint = `/files/uploadMany`;
      images?.length && images.map((i) => formData.append("files", i));
      await axios
        .post(endpoint, formData, { auth: true })
        .then((res) => {
          params.images = res.data;
          params.mainImage = main;
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        })
        .catch(() => {
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        });
    }

    if (data?.charities?.length > 0) {
      params.charities = data.charities.map((item) => item.id);
    } else {
      params.charities = [];
    }
    if (data?.category) {
      params.category = data.category.id;
    }
    if (data?.endDate) {
      const endDate = new Date(data.endDate);
      endDate.setHours(23, 59, 59, 999);
      params.endDate = endDate;
    }

    if (clickedButton === "saveAsDraft") {
      if (data?.name) {
        params.status = fundraiserStatuses.DRAFT;
        dispatch(fundraiserActions.createFundraiser(params));
      } else {
        setError("name", {
          type: "manual",
          message: "This Field is required",
        });
      }
    }

    if (clickedButton === "publish") {
      if (
        data?.name &&
        data?.charities?.length > 0 &&
        data?.category &&
        data?.endDate &&
        data?.description
      ) {
        params.status = fundraiserStatuses.LIVE;
        dispatch(fundraiserActions.createFundraiser(params));
      } else {
        const errType = !data?.name
          ? "name"
          : !data?.charities
          ? "charities"
          : data?.charities?.length === 0
          ? "charities"
          : !data?.category
          ? "category"
          : !data?.endDate
          ? "endDate"
          : "";

        setError(errType, {
          type: "manual",
          message: "This Field is required",
        });
      }
    }
  };

  const handleSetMain = (main) => {
    setMain(main);
  };
  const handleUpload = (item) => {
    setImages([...images, item]);
  };

  const handleDeleteImage = (item, index) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);
    setMain(0);
  };

  return (
    <div className="create-fundraiser-page">
      <div className="create-fundraiser-form">
        <div className="create-fundraiser-back">
          <BackBtn handleClick={navigateBack} />
        </div>

        <div className="create-fundraiser-form-box">
          <div className="create-fundraiser-head">
            <p className="create-fundraiser-title">Create Fundraiser</p>
            <button onClick={navigateBack}>
              <Svg.Close />
            </button>
          </div>

          <div className="create-fundraiser-body">
            <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
              <InputMain
                name="name"
                type="text"
                control={control}
                placeholder="Write Event Name"
                labelHeader="Event Name"
              />
              <CheckboxesTags
                list={charities?.charities || []}
                renderLabel={(option) => option?.name}
                name="charities"
                type="text"
                control={control}
                placeholder="Select Charities"
                labelHeader="Assigned Charities"
              />
              <div className="create-charity-inputs-box">
                <SelectRadio
                  list={fundraiserCategories}
                  renderLabel={(option) => option?.title}
                  name="category"
                  type="text"
                  control={control}
                  placeholder="Select Type"
                  labelHeader="Type"
                />
                <DateCalendarForm
                  name="endDate"
                  control={control}
                  placeholder="Write Event Name"
                  label="End Date*"
                  min={new Date()}
                />
              </div>

              <TextAreaMain
                name="description"
                type="text"
                control={control}
                placeholder="Write Description..."
                labelHeader="Description"
              />

              <Upload
                handleSetMain={handleSetMain}
                handleChange={handleUpload}
                create={true}
                handleDelete={handleDeleteImage}
              />

              <div className="create-fundraiser-action-wrapper">
                <div className="create-fundraiser-action-box flex-end">
                  <button
                    name="saveAsDraft"
                    type={"submit"}
                    className="save-as-drat"
                    onClick={() => setClickedButton("saveAsDraft")}
                  >
                    {(uploadLoader || loader) &&
                    clickedButton === "saveAsDraft" ? (
                      <MinLoader margin={0} />
                    ) : (
                      "Save as Draft"
                    )}
                  </button>
                  <button
                    name="publish"
                    type={"submit"}
                    className="main-button"
                    onClick={() => setClickedButton("publish")}
                  >
                    {(uploadLoader || loader) && clickedButton === "publish" ? (
                      <MinLoader />
                    ) : (
                      <p>Publish</p>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
