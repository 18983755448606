import React, { Fragment } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FindLoad, SaveParams } from "utils";
import { PaginationItem, PageLoader, TextRow } from "components";
import { Svg } from "assets";
import { useLocation, useNavigate } from "react-router";
import { NotYet } from "./notYet";
import { SortBy } from "../../fragments";

export const FullTable = ({
  classNameWrapper,
  head,
  body,
  list,
  loadingType,
  handleClick,
  listCount,
  mobileCard,
}) => {
  const loader = FindLoad(loadingType ? loadingType : "");
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;

  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const isMemory = fundraiserById?.type === "MEMORY";

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleClick(i, name), i);
      } else {
        return item?.rowText(i);
      }
    }
  };

  const handleSort = (current) => {
    const params = {
      ...info,
    };

    params.sortType = current;
    if (info?.orderType === "DESC") {
      params.orderType = "ASC";
    } else {
      params.orderType = "DESC";
    }
    SaveParams(location?.pathname, navigator, params);
  };

  const handleClickRow = (item, i) => {
    if (item?.notClickable !== true && handleClick) {
      handleClick(i);
    }
  };

  if (loader?.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <PageLoader height="58vh" />
      </div>
    );
  }

  const classesWrapper = ["full-table-styles"];
  if (classNameWrapper) classesWrapper.push(classNameWrapper);

  return (
    <div className={classesWrapper.join(" ")}>
      <div className="table-wrapper-desktop">
        <TableContainer className="table-container" component={Paper}>
          {list?.length ? (
            <Table
              stickyHeader
              className="table"
              size="small"
              aria-label="a dense table"
            >
              <TableHead className="table-head">
                <TableRow>
                  {head?.map(
                    (i, j) =>
                      i && (
                        <TableCell
                          key={j}
                          style={i?.width ? { width: i.width } : {}}
                        >
                          <div className="table-cell-box">
                            {/*<p className="full-width">*/}
                            <TextRow text={i?.title} textWidth={8} />
                            {/*</p>*/}

                            {i?.sortType && (
                              <button
                                style={
                                  info?.orderType &&
                                  info?.sortType === i?.sortType
                                    ? { opacity: 1 }
                                    : { opacity: 0.5 }
                                }
                                onClick={() => handleSort(i.sortType)}
                              >
                                <Svg.SortSvg
                                  style={
                                    info?.sortType === i?.sortType &&
                                    info?.orderType === "DESC"
                                      ? { transform: "rotate(180deg)" }
                                      : {}
                                  }
                                />
                              </button>
                            )}

                            {!!i?.sortItems?.length && (
                              <SortBy
                                classNameSortByButton="table-cell-box-sort-icon"
                                items={i?.sortItems}
                              />
                            )}
                          </div>
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map(
                  (i, j) =>
                    i && (
                      <Fragment key={j}>
                        <TableRow
                          style={handleClick ? { cursor: "pointer" } : {}}
                          className="table-row"
                        >
                          {body?.map((item, ke) => {
                            if (item) {
                              return (
                                <Fragment key={ke}>
                                  <TableCell
                                    sx={
                                      item?.width
                                        ? {
                                            width: item.width,
                                            maxWidth: item?.width,
                                          }
                                        : {}
                                    }
                                    className=""
                                    onClick={() => handleClickRow(item, i)}
                                  >
                                    {renderItems(i, item) || "Not Set"}
                                  </TableCell>
                                </Fragment>
                              );
                            }
                          })}
                        </TableRow>
                      </Fragment>
                    )
                )}
              </TableBody>
            </Table>
          ) : (
            <NotYet height={"60vh"} />
          )}
        </TableContainer>

        {listCount > 0 && <PaginationItem count={listCount} list={list} />}
      </div>

      <div className="table-wrapper-mobile">
        <div className="table-wrapper-mobile-wrapper">
          {list?.length ? (
            list?.map((i, j) => (
              <div className="table-mobile-card" key={j}>
                {mobileCard?.map((item, ke) => {
                  if (item) {
                    return (
                      <div key={ke} onClick={() => handleClickRow(item, i)}>
                        {renderItems(i, item) || "Not Set"}
                      </div>
                    );
                  }
                })}
              </div>
            ))
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            >
              <NotYet isMemory={isMemory} height={"60vh"} />
            </div>
          )}
        </div>
        {listCount > 0 && (
          <PaginationItem isMemory={isMemory} count={listCount} list={list} />
        )}
      </div>
    </div>
  );
};
