import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import { NumericFormat } from "react-number-format";

export const TextToolTip = ({
  text,
  content,
  lineClamp = 1,
  arrow = false,
  priceRow = false,
  allowPlusPref = false,
  allowMinusPref = false,
  classNameText,
  textStyles,
}) => {
  const [dontShowTooltip, setShowingTooltip] = useState(true);
  const targetRef = useRef(null);

  const checkOverflow = () => {
    if (targetRef.current?.scrollHeight > targetRef.current?.clientHeight) {
      setShowingTooltip(false);
    } else {
      setShowingTooltip(true);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <div className="text-tool-tip-wrapper">
      <Tooltip
        title={
          priceRow ? (
            <NumericFormat
              value={content}
              displayType={"text"}
              thousandSeparator
              allowNegative={false}
              prefix={"$"}
            />
          ) : (
            content
          )
        }
        disableHoverListener={dontShowTooltip}
        arrow={arrow}
      >
        <div
          id={"text-tooltip"}
          className={`main-tooltip-text ${classNameText ? classNameText : ""}`}
          style={{ WebkitLineClamp: lineClamp, ...textStyles }}
          ref={targetRef}
        >
          {priceRow ? (
            <NumericFormat
              value={Number(text).toFixed(2)}
              displayType={"text"}
              thousandSeparator
              allowNegative={false}
              prefix={allowPlusPref ? "+$" : allowMinusPref ? "-$" : "$"}
            />
          ) : (
            text
          )}
        </div>
      </Tooltip>
    </div>
  );
};
