import React from "react";
import { HeaderDrawerMain, TextToolTip } from "components";
import { FundraiserDetailsCard } from "../fundraiserDetailsCard";
import { ProfileInfo } from "utils";

export const DetailDonationsModal = ({ item, closeDrawer }) => {
  const { ifAdmin } = ProfileInfo();

  return (
    <div>
      <HeaderDrawerMain title="Donation Details" closeModal={closeDrawer} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px",
        }}
      >
        {item?.charities?.map((i, k) => (
          <FundraiserDetailsCard
            key={k}
            headerColor="#F4F4F4"
            title={i?.charityName}
            list={[
              {
                name: "Net Amount",
                description: (
                  <TextToolTip
                    classNameText="fundraiser-details-single-card-desk"
                    priceRow
                    arrow
                    allowPlusPref
                    text={+i?.netAmount + +i?.serviceFee}
                    content={+i?.netAmount + +i?.serviceFee}
                  />
                ),
              },

              {
                name: "Service Fee",
                description: (
                  <TextToolTip
                    classNameText="fundraiser-details-single-card-desk service-fee"
                    priceRow
                    arrow
                    allowMinusPref
                    text={+i?.serviceFee}
                    content={+i?.serviceFee}
                  />
                ),
              },

              {
                name: "Total Received",
                description: (
                  <TextToolTip
                    classNameText="fundraiser-details-single-card-desk total"
                    priceRow
                    arrow
                    allowPlusPref
                    text={+i?.netAmount}
                    content={+i?.netAmount}
                  />
                ),
              },
            ]}
          />
        ))}

        {item?.keepAmount && (
          <FundraiserDetailsCard
            headerColor={"#DBEDDB"}
            titleColor={"#1C212D"}
            borderColor={"#E0E1E2"}
            title="Creator Portion"
            list={[
              {
                name: "Net Amount",
                description: (
                  <TextToolTip
                    classNameText="fundraiser-details-single-card-desk"
                    arrow
                    priceRow
                    allowPlusPref
                    text={
                      +item?.keepAmount?.netAmount +
                      +item?.keepAmount?.serviceFee
                    }
                    content={
                      +item?.keepAmount?.netAmount +
                      +item?.keepAmount?.serviceFee
                    }
                  />
                ),
              },

              {
                name: "Service Fee",
                description: (
                  <TextToolTip
                    classNameText="fundraiser-details-single-card-desk service-fee"
                    priceRow
                    allowMinusPref
                    arrow
                    text={ifAdmin ? 0 : +item?.keepAmount?.serviceFee}
                    content={ifAdmin ? 0 : +item?.keepAmount?.serviceFee}
                  />
                ),
              },

              {
                name: "Total Received",
                description: (
                  <TextToolTip
                    classNameText="fundraiser-details-single-card-desk total"
                    priceRow
                    arrow
                    allowPlusPref
                    text={+item?.keepAmount?.netAmount}
                    content={+item?.keepAmount?.netAmount}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};
