import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { SaveParams } from "utils";
import { Svg } from "assets";

export const SearchInput = ({
  placeholder,
  searchKey,
  allowClearSvg,
  className,
  SearchSvg,
  handleClose,
  wait,
  maxWait,
}) => {
  const [text, setText] = useState("");
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;

  useEffect(() => {
    if (info) {
      setText(info[searchKey] || "");
    }
  }, [info]);

  const debounced = useDebouncedCallback(
    (value) => {
      const params = {
        ...info,
      };
      params.skip = 0;
      params.page = 1;

      if (value) {
        params[searchKey] = value;
      } else {
        delete params[searchKey];
      }
      SaveParams(location?.pathname, navigator, params);
    },
    wait ?? 500,
    { maxWait: maxWait ?? 2000 }
  );

  const handleChange = (e) => {
    debounced(e.target.value);
    setText(e.target.value);
  };

  const handleClear = () => {
    debounced("");
    setText("");
    const params = {
      ...info,
    };
    params.skip = 0;
    params.page = 1;

    delete params[searchKey];

    SaveParams(location?.pathname, navigator, params);
  };

  return (
    <div className={`search-box ${className ? className : ""}`}>
      <button className="search-box-button">
        {SearchSvg || <Svg.Search />}
      </button>

      <input
        type="text"
        name={searchKey}
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
      />
      {allowClearSvg && (
        <button
          className="search-clear-button"
          onClick={() => {
            handleClear();
            handleClose && handleClose();
          }}
        >
          <Svg.Close />
        </button>
      )}
    </div>
  );
};
