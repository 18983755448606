import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { FullTable } from "components";
import { fundraiserActions } from "store";
import {
  CHARITY_FUNDRAISER_TABLE_ACTION_TYPE,
  charityFundraisersBody,
  charityFundraisersHead,
  fundraiserMobileCard,
} from "./constants";
import { limit, useWindowDimensions } from "utils";

export const CharityFundraisers = () => {
  const { fundraisers } = useSelector((state) => ({
    fundraisers: state.fundraiser.fundraisers,
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const info = location?.state;
  const locationParams = useParams();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    const params = {
      ...info,
      limit,
      charity: locationParams?.id,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete params.page;
    delete params.tab;
    delete params.status;
    dispatch(fundraiserActions.getFundraisers(params));
  }, [dispatch, info]);

  const goToFundraiser = (item) => {
    navigate(`/events/${item?.id}`);
  };

  return (
    <div>
      <FullTable
        classNameWrapper="charities-events-table-wrapper"
        head={charityFundraisersHead(width)}
        body={charityFundraisersBody(width)}
        mobileCard={fundraiserMobileCard}
        loadingType={CHARITY_FUNDRAISER_TABLE_ACTION_TYPE}
        list={fundraisers?.fundraisers}
        listCount={fundraisers?.count}
        handleClick={goToFundraiser}
      />
    </div>
  );
};
