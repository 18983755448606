import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Svg } from "assets";
import { SaveParams } from "utils";

export const SortBy = ({ title, items, classNameSortByButton, Icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;

  const toggleMenu = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleChange = (item) => {
    setValue(item?.value);
    setAnchorEl(null);
    const params = {
      ...info,
    };
    if (item?.sortType) params.sortType = item?.sortType;
    if (item?.orderType) params.orderType = item?.orderType;

    item?.type
      ? (params.type = item?.type)
      : params.type
      ? delete params.type
      : "";

    params.sortValue = item?.value;

    SaveParams(location?.pathname, navigator, params);
  };

  return (
    <div className="sort-by-wrapper">
      <button
        style={info?.sortValue ? { opacity: 1 } : { opacity: 0.5 }}
        className={`sort-by-button ${
          classNameSortByButton ? classNameSortByButton : ""
        } ${value ? "sort-by-button-active" : ""}`}
        onClick={toggleMenu}
      >
        {Icon ? <Icon /> : <Svg.SortSvg />}

        {title && <p className="sort-by-button-title">{title}</p>}
      </button>

      <Popover
        className="sort-by-menu-wrapper"
        classes={{ paper: "sort-by-menu-paper" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={toggleMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={info?.sortValue}
          >
            {items?.map((item, index) => (
              <FormControlLabel
                className={value === item?.value ? "sort-by-active" : ""}
                onClick={() => handleChange(item)}
                key={index}
                value={item?.value}
                control={<Radio />}
                label={item?.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Popover>
    </div>
  );
};
