import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { FullTableAccordion } from "components";
import { donationActions } from "store";
import {
  DONATION_TABLE_ACTION_TYPE,
  donationsMobileCard,
  fundraiserDonationBody,
  fundraiserDonationHead,
} from "./constants";
import { limit, useWindowDimensions } from "utils";

export const FundraiserDonation = () => {
  const { donations } = useSelector((state) => ({
    donations: state.donation.donations,
  }));
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const info = location?.state;
  const { width } = useWindowDimensions();

  useEffect(() => {
    const sendInfo = {
      ...info,
      limit,
      fundraiser: params?.id,
    };
    params.skip = info?.skip ? info?.skip : 0;
    delete sendInfo.page;
    delete sendInfo.tab;
    dispatch(donationActions.getDonations(sendInfo));
  }, [info]);

  return (
    <div>
      <FullTableAccordion
        head={fundraiserDonationHead(width)}
        body={fundraiserDonationBody(width)}
        list={donations?.donations}
        listCount={donations?.count}
        loadingType={DONATION_TABLE_ACTION_TYPE}
        mobileCard={donationsMobileCard}
      />
    </div>
  );
};
